import React from 'react';
import SfyHeader from "./SfyHeader"
import { Grid, TextField , Button, Box, Paper, Typography, Select, MenuItem } from '@material-ui/core';
import SfyLogo from './images/SFY_logo.png'
//import AuntBerthaLogo from './images/AuntBerthaLogo.png'

// global data
import RegistrationData from "./RegistrationData";
import SettingsData from "./SettingsData";

function handleVerification(setScreen)
{
    //console.log("VS.handleVerification()")
    setScreen('verification-error-screen')
}

function isCodeVerifiable(verificationCode) {
    if (verificationCode ==='')
        return false
    if (9!==verificationCode.length)
        return false
    // make the correctly sized verificationCode availible for comparison
    RegistrationData.verificationCodeFromClient = verificationCode.toUpperCase() 
    return true
}

export default function VerificationScreen(props) {
    const [role, setRole] = React.useState(SettingsData.LawEnforcementRole);
    const [canVerify, setVerifiable] = React.useState(false);
    const [clientCode, setClientCode] = React.useState("");
    const onCodeChange = event => {
        let code = event.target.value
        setClientCode(code)
        let codeMatches = isCodeVerifiable(code)
        let roleSet = !SettingsData.inDemoMode || ('empty' !== SettingsData.LawEnforcementRole)
        console.log("onCodeChange--codeMatches: " +codeMatches+ ", roleSet: " + roleSet)
        console.log("onCodeChange--code: " + code + ", role: " + SettingsData.LawEnforcementRole)
        setVerifiable (codeMatches && roleSet)
    }

    const handleRole = event => {
        SettingsData.LawEnforcementRole = event.target.value
        setRole(SettingsData.LawEnforcementRole)
        let codeMatches = isCodeVerifiable(clientCode)
        let roleSet = !SettingsData.inDemoMode || ('empty' !== SettingsData.LawEnforcementRole)
        console.log("handleRole--codeMatches: " +codeMatches+ ", roleSet: " + roleSet)
        console.log("onCodeChange--code: " + clientCode + ", role: " + SettingsData.LawEnforcementRole)
        setVerifiable (codeMatches && roleSet)
    }
    
    function demoAdditions() {
        console.log("SD.inDemoMode: " + SettingsData.inDemoMode)
        if (SettingsData.inDemoMode) {
            return ( 
                <div>
                    <Box mt={2}>  
                        <Grid container spacing={1} alignItems="center">
                            <Grid item> 
                                <Typography 
                                    variant="h6" 
                                    color="secondary">
                                    Your Role:
                                </Typography>  
                            </Grid>
                            <Grid item xs>
                                <Select 
                                    fullWidth 
                                    variant="outlined"
                                    labelId="select-role"
                                    id="select-role"
                                    value={role}
                                    onChange={handleRole}
                                >
                                    <MenuItem value="empty"><i>required</i></MenuItem>
                                    <MenuItem key="PoliceChief" value="PoliceChief">Police Chief</MenuItem>
                                    <MenuItem key="Sheriff" value="Sheriff">Sheriff</MenuItem>
                                    <MenuItem key="LEO" value="LEO">Police Officer</MenuItem>
                                    <MenuItem key="SheriffDeputy" value="SheriffDeputy">Sheriff's Deputy</MenuItem>
                                    <MenuItem key="ProbationOfficer"value="ProbationOfficer">Probation Officer</MenuItem>
                                    <MenuItem key="JdaiCoord"value="JdaiCoord">JDAI Coordinator</MenuItem>
                                    <MenuItem key="SocialWorker"value="SocialWorker">Social Worker</MenuItem>
                                    <MenuItem key="Judge"value="Judge">Judge</MenuItem>
                                    <MenuItem key="Prosecutor"value="Prosecutor">Prosecutor</MenuItem>
                                    <MenuItem key="JuvenileDefender"value="JuvenileDefender">Juvenile Defender</MenuItem>
                                    <MenuItem key="Other"value="Other"><i>Other</i></MenuItem>
                                </Select> 
                            </Grid>
                        </Grid>
                     </Box>
                    {/*
                    <Grid item xs={12} sm={6}>
                        <Box mt={3} > 
                            <Typography component={'span'} variant="h6" color="secondary" >
                                Your Role:
                            </Typography>
                        </Box> 
                        <Box mt={-4} ml={13}>
                            <Select
                                labelId="select-role"
                                id="select-role"
                                value={role}
                                onChange={handleRole}
                            >
                                <MenuItem value="empty"><i>required</i></MenuItem>
                                <MenuItem key="PoliceChief" value="PoliceChief">Police Chief</MenuItem>
                                <MenuItem key="Sheriff" value="Sheriff">Sheriff</MenuItem>
                                <MenuItem key="LEO" value="LEO">Police Officer</MenuItem>
                                <MenuItem key="SheriffDeputy" value="SheriffDeputy">Sheriff's Deputy</MenuItem>
                                <MenuItem key="ProbationOfficer"value="ProbationOfficer">Probation Officer</MenuItem>
                                <MenuItem key="JdaiCoord"value="JdaiCoord">JDAI Coordinator</MenuItem>
                                <MenuItem key="SocialWorker"value="SocialWorker">Social Worker</MenuItem>
                                <MenuItem key="Judge"value="Judge">Judge</MenuItem>
                                <MenuItem key="Prosecutor"value="Prosecutor">Prosecutor</MenuItem>
                                <MenuItem key="JuvenileDefender"value="JuvenileDefender">Juvenile Defender</MenuItem>
                                <MenuItem key="Other"value="Other"><i>Other</i></MenuItem>
                            </Select> 
                        </Box> 
                    </Grid>*/}
                </div>
            )
        }
        else {
            return null
        }
    }

    //console.log('Show Verification Screen')
    let verificationPlaceholder = 'Enter the code sent to '+SettingsData.getActualEmailAddress()
    let testingString = SettingsData.getTestingString()

    return ( 
        <Grid container direction='column' spacing={3}>
            <Grid>
                <SfyHeader screenId={'verification-screen'}  lastScreenId={props.lastScreenId} setScreen={props.setScreen}  Settings={false} />
            </Grid>
            <Paper style={{maxHeight: '100vh', overflow: 'auto'}}>
                <Box ml={3} mt= {2} mr={3}>
                    <img src={SfyLogo} alt='SFY Logo' />
                    <Grid item xs={12} sm={6}>
                        <Box mt= {3}>
                            <Grid item>
                                POLICE DEPARTMENT
                            </Grid>
                        </Box>

                        <Grid item>
                            <Button fullWidth size='large' color='primary' variant="outlined">{SettingsData.policeDepartment['agency']}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box mt= {3}>
                            <Grid item>
                                EMAIL ADDRESS
                            </Grid>
                        </Box>
                        <Grid item>
                            <Button fullWidth size='large' color='primary' variant="outlined">{SettingsData.getActualEmailAddress() + testingString}</Button>
                        </Grid>
                    </Grid>

                    <div>
                        {demoAdditions()}
                    </div>

                    <Grid item xs={12} sm={6}>
                        <Box mt= {3}>
                            <Grid item>
                                VERIFICATION CODE
                            </Grid>
                        </Box>
                        <Grid item>
                            <TextField 
                                variant='outlined' 
                                fullWidth color='primary' 
                                placeholder={verificationPlaceholder}
                                onChange ={onCodeChange}
                                />
                        </Grid>
                    </Grid>

                    <Box mt={3}>
                        <Grid item xs={12} sm={6}>
                            <Button 
                                onClick={() => {handleVerification(props.setScreen);}}
                                variant="contained" 
                                color="primary" 
                                disabled={!canVerify}
                            >
                                Verify Registration
                            </Button>
                        </Grid> 
                    </Box>
                </Box>
            </Paper>
        </Grid >
    );
}